import * as Types from "../types";

import clienteAxios from "../config/axios";
import Swal from "sweetalert2";

export function getCajaAction() {
  return async (dispatch) => {
    dispatch(getCaja());
    try {
      const response = await clienteAxios.get("caja/datos-generales");
      if (response.data.data) {
        dispatch(getCajaExito(response.data.data));
      }
    } catch (error) {
      dispatch(getCajaError(error));
    }
  };
}

const getCaja = () => ({
  type: Types.CAJA_DATOS_GENERALES,
});

const getCajaExito = (caja) => ({
  type: Types.CAJA_DATOS_GENERALES_EXITO,
  payload: caja,
});

const getCajaError = (error) => ({
  type: Types.CAJA_DATOS_GENERALES_ERROR,
  payload: error,
});

export function getCajasAction() {
  return async (dispatch) => {
    dispatch(getCajas());
    try {
      const response = await clienteAxios.get("caja");
      if (response.data.data) {
        dispatch(getCajasExito(response.data.data));
      }
    } catch (error) {
      dispatch(getCajasError(error));
    }
  };
}

const getCajas = () => ({
  type: Types.LISTA_CAJAS,
});

const getCajasExito = (cajas) => ({
  type: Types.LISTA_CAJAS_EXITO,
  payload: cajas,
});

const getCajasError = (error) => ({
  type: Types.LISTA_CAJAS_ERROR,
  payload: error,
});

export function getCajaEstadisticasAction() {
  return async (dispatch) => {
    dispatch(getCajaEstadisticas());
    try {
      const response = await clienteAxios.get("caja/estadisticas");
      if (response.data.data) {
        dispatch(getCajaEstadisticasExito(response.data.data));
      }
    } catch (error) {
      dispatch(getCajaEstadisticasError(error));
    }
  };
}

const getCajaEstadisticas = () => ({
  type: Types.CAJA_ESTADISTICAS,
});

const getCajaEstadisticasExito = (estadisticas) => ({
  type: Types.CAJA_ESTADISTICAS_EXITO,
  payload: estadisticas,
});

const getCajaEstadisticasError = (error) => ({
  type: Types.CAJA_ESTADISTICAS_ERROR,
  payload: error,
});

export function abrirCajaAction(idCaja, dinero_caja, usuariosAbrirCaja, state) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.post("caja/abrir", {
        id_caja: idCaja,
        dinero_caja,
        usuarios_compartir_caja: usuariosAbrirCaja,
      });
      if (response.data.data) {
        Swal.fire("¡Muy bien!", response.data.message, "success");
        dispatch(abrirCajaExito(response.data.data));
      }
    } catch (error) {
      dispatch(abrirCajaError(error));
    }

    state.accionRealizada = true;
  };
}

const abrirCajaExito = (caja) => ({
  type: Types.CAJA_APERTURA_EXITO,
  payload: caja,
});

const abrirCajaError = (error) => ({
  type: Types.CAJA_APERTURA_ERROR,
  payload: error,
});

export function cerrarCajaAction(dinero_caja, total_cierre_reportado, state) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.post("caja/cerrar", {
        dinero_caja,
        total_cierre_reportado,
      });
      if (response.data.data) {
        Swal.fire("¡Muy bien!", response.data.message, "success");
        dispatch(cerrarCajaExito(response.data.data));
      }
    } catch (error) {
      dispatch(cerrarCajaError(error));
    }

    state.accionRealizada = true;
  };
}

const cerrarCajaExito = (caja) => ({
  type: Types.CAJA_CIERRE_EXITO,
  payload: caja,
});

const cerrarCajaError = (error) => ({
  type: Types.CAJA_CIERRE_ERROR,
  payload: error,
});

export function showModalCajaAction(estatus) {
  return (dispatch) => {
    dispatch(showModalCaja(estatus));
  };
}

const showModalCaja = (estatus) => ({
  type: Types.MODAL_CAJA_APERTURA_CIERRE,
  payload: estatus,
});

export function showModalCajaCorteZAction(estatus) {
  return (dispatch) => {
    dispatch(showModalCajaCorteZ(estatus));
  };
}

const showModalCajaCorteZ = (estatus) => ({
  type: Types.MODAL_CAJA_CORTE_Z,
  payload: estatus,
});

export function imprimirCorteZAction(periodo, fechaInicio, fechaFin = null) {
  return async () => {
    try {
      const url =
        periodo == "diario"
          ? `imprimir/corte-z-caja-diario?fecha_inicio=${fechaInicio}&fecha_fin=${fechaFin}`
          : `imprimir/corte-z-caja-mensual/${fechaInicio}`;
      const response = await clienteAxios.get(url, { responseType: "blob" });
      const file = new Blob([response.data], { type: response.data.type });

      const fileURL = URL.createObjectURL(file);
      window.open(fileURL);
    } catch (error) {
      //dispatch( cerrarCajaError(error) );
    }
  };
}

export function cambiarCajaAction(caja = null) {
  return async (dispatch) => {
    try {
      const response = await clienteAxios.post("usuarios/cambiar-caja", {
        caja_uuid: caja?.uuid,
      });
      if (response.data.success == true) {
        dispatch(cambiarCaja(caja));
      }
    } catch (error) {
      //dispatch( cerrarCajaError(error) );
    }
  };
}

const cambiarCaja = (caja) => ({
  type: Types.CAMBIAR_CAJA,
  payload: caja,
});
